<template>
  <div class="w-full h-full flex items-center py-2 px-3">
    <div
      class="w-full h-full flex items-center justify-center rounded-md"
      :class="style.background">
      <span :class="style.color">
        {{ score }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
const props = defineProps({
  lessonSummary: {
    type: Object,
    default: () => {},
  },
});
const levels = [
  { range: [0, 50], style: { color: 'text-red-500', background: 'bg-red-50' } },
  {
    range: [51, 79],
    style: { color: 'text-orange-500', background: 'bg-orange-50' },
  },
  {
    range: [80, 120],
    style: { color: 'text-green-700', background: 'bg-green-50' },
  },
];
const isInRange = (value, [min, max]: number[]) => value >= min && value <= max;

const getCompletionLevel = score => {
  const level = levels.find(({ range }) => isInRange(score, range));
  return level ?? {};
};

const score = computed(() =>
  props.lessonSummary.progress !== 100
    ? '-'
    : Math.round(props.lessonSummary.score),
);
const style = computed(() => {
  if (props.lessonSummary.progress !== 100) {
    return {
      color: 'text-secondary-600',
      background: 'bg-secondary-100',
    };
  }
  const level = getCompletionLevel(props.lessonSummary.score);
  return level.style ?? {};
});
</script>
