import createAlert from 'sweetalert';

export const getRandomElementFromArray = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const defaultAvatar =
  'https://res.cloudinary.com/dcodkxpej/image/upload/f_auto,q_auto,c_fill,h_200,w_200/v1672660848/Amit/Avatars/avatar_0-10_geljvo.svg';

export const showAlert = (title, text, icon, buttons) => {
  return createAlert({
    title: title,
    text: text,
    icon: icon,
    buttons: buttons,
    dangerMode: true,
  });
};
