<template>
  <BaseWidget>
    <template #title>
      <div>תירגול מעשי</div>
    </template>
    <template #default>
      <div v-if="enrollment">
        <div
          v-for="({ stage, locked }, index) in enrollment.summary"
          :key="stage.id"
          class="flex items-start py-1 w-36 gap-4 text-sm">
          <div
            class="p-2 rounded-full mb-1"
            :class="{
              'cursor-default bg-primary-200': !locked,
              'bg-secondary-300 cursor-pointer': locked,
            }"
            @click="locked && unlockGroupStage(stage.id)">
            <Unlock
              v-if="!locked"
              class="h-3 w-3 fill-primary-200 stroke-primary"/>
            <Lock v-else class="h-3 w-3 fill-secondary-300 stroke-[#B0BAC8]" />
          </div>
          <RouterLink
            target="_blank"
            :to="{
              name: 'project-preview',
              params: { project: enrollment.project, stage: stage.id },
            }">
            <div
              class="cursor-pointer whitespace-nowrap text-sm font-simplerRegular">
              שלב {{ index + 1 }}
            </div>
            <div
              title="title"
              class="whitespace-nowrap text-xs font-simplerLight leading-1 h-5">
              {{ stage.title }}
            </div>
          </RouterLink>
        </div>
      </div>
      <BaseButton
        v-else
        class="w-full"
        color="default"
        @click="showModal = true">
        <span class="font-simplerBold text-white">הפצת תירגול מעשי לקבוצה</span>
      </BaseButton>
    </template>
    <template v-if="enrollment" #footer>
      <RouterLink
        :to="{
          name: 'project-progress',
          params: { group, project: enrollment.project },
        }">
        <div
          class="flex justify-center items-center font-simplerBold text-primary-700 cursor-pointer">
          לבדיקת התירגול >
        </div>
      </RouterLink>
    </template>
  </BaseWidget>
  <ProjectDistributeModal
    v-show="showModal"
    @close="closeModal"
    @distribute="distribute"/>
</template>

<script setup lang="ts">
import { BaseWidget, BaseButton } from '@nwire/amit-design-system';
import { ref, watchEffect } from 'vue';
import { createEnrollments, fetchEnrollments } from '/@/app/services/projects';
import ProjectDistributeModal from '/@/views/partials/ProjectDistributeModal.vue';
import { Lock, Unlock } from 'lucide-vue-next';
import { unlockStage } from '/@/app/services/projects';
import swal from 'sweetalert';

const props = defineProps({
  project: {
    type: String,
    required: true,
  },
  group: {
    type: String,
    required: true,
  },
});

const showModal = ref(false);
const enrollment = ref();

const setEnrollment = () => {
  if (!props.group || !props.project) {
    return;
  }
  const { onSuccess } = fetchEnrollments(props.project, props.group);
  onSuccess(({ data }) => {
    if (data.length) {
      enrollment.value = data[0];
    }
  });
};

watchEffect(() => {
  setEnrollment();
});

const closeModal = () => {
  showModal.value = false;
};

const distribute = introduction => {
  if (!props.group || !props.project) {
    closeModal();
    return;
  }

  const { onSuccess, onError } = createEnrollments(
    props.project,
    props.group,
    introduction,
  );
  onSuccess(() => {
    setEnrollment();
    closeModal();
  });
  onError(() => {
    closeModal();
  });
};

const unlockGroupStage = async stage => {
  const confirmed = await swal({
    text: 'בלחיצה על אישור השלב יפתח לתלמידים.',
    buttons: ['ביטול', 'אישור'],
  });
  if (!confirmed) {
    return;
  }

  const { onSuccess } = unlockStage(props.project, stage, props.group);
  onSuccess(() => {
    const foundStage = enrollment.value.summary.find(
      summary => summary.stage.id === stage,
    );
    if (foundStage) {
      foundStage.locked = false;
    }
  });
};
</script>
