<template>
  <BaseModal size="5xl" @close="closeModal">
    <template #header>
      <div class="font-simplerBold text-lg">הפצת התירגול לקבוצה</div>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="font-simplerBold">
          <div>במסך הפתיחה של הפרויקט תופיע לתלמידים הנחיה.</div>
          <div>באפשרותכם לבחור אם להפיץ את הטקסט הכתוב מטה או לערוך אותו:</div>
        </div>
        <BaseTextArea v-model="introModel" :rows="15" :max-length="500" />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <BaseButton color="none" @click="closeModal">ביטול</BaseButton>
        <BaseButton color="blue" @click="distribute">
          <span class="text-white">הפצה</span>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { BaseModal, BaseButton, BaseTextArea } from '@nwire/amit-design-system';
import { ref } from 'vue';
const emit = defineEmits(['close', 'distribute']);

const defaultText =
  'לאחר שקיבלת כלים שונים לניהול עצמי בחודשים האחרונים, אנו ניגשים לתהליך מסכם בו תתכננו ותיישמו את הכלים שלמדתם על פרויקט מהחיים האישיים שלכם🙏. \n\nתהליך הגשת העבודה יתחלק ל-3 שלבים:\nשלב 1: תכנון \nשלב 2: יישום ובקרה \nשלב 3: הגשת העבודה וסיכום \n\nבסיום כל שלב, נפגש לשיחה אישית כדי לראות את ההתקדמות שלכם ולחשוב איך ממשיכים הלאה כדי להשיג את המטרה. \n\nבהצלחה רבה!';
const introModel = ref(defaultText);
const distribute = () => {
  emit('distribute', introModel.value);
};

const closeModal = () => {
  emit('close');
};
</script>
