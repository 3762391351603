<template>
  <BaseWidget>
    <template #title>
      <div>חומרי לימוד לשימוש בקורס</div>
    </template>
    <template #default>
      <div class="overflow-y-auto vl-parent">
        <ul v-if="fileItems.length">
          <li
            v-for="(fileItem, index) in fileItems"
            :key="index"
            class="flex items-center justify-between mb-2">
            <a :href="fileItem.url" class="flex items-center" target="_blank">
              <fileText class="stroke-primary ml-1" />
              <span class="text-primary mt-0.5 w-70 truncate" dir="ltr">
                {{ fileItem.title }}
              </span>
            </a>
            <span>
              <Trash2
                class="cursor-pointer"
                @click="removeFile(fileItem._id)"/>
            </span>
          </li>
        </ul>
        <div v-else class="flex flex-col p-4 py-0">
          <span class="font-simpler text-sm text-center">
            פה ניתן לעלות תכנים שיעזרו לך להעביר את השיעור בצורה הטובה ביותר
          </span>
          <div
            class="flex flex-col justify-center items-center pt-6 pb-1 cursor-pointer"
            @click="triggerFileInput">
            <UploadIcon />
          </div>
        </div>
        <LoadingOverlay v-model:active="isUploading" :is-full-page="false" />
      </div>
    </template>
    <template #footer>
      <BaseButton
        color="none"
        class="flex flex-col w-full justify-center items-center text-secondary-900 text-sm"
        @click="triggerFileInput">
        <div
          class="flex gap-1 font-simplerBold text-md text-primary text-center cursor-pointer">
          <PlusIcon class="stroke-primary" />
          העלאת קובץ חדש
        </div>
        <span class="text-secondary-500 text-sm">גודל מקסימלי: 2MB</span>
        <span class="text-secondary-500 text-sm">
          סוגי קבצים מותרים: PDF, קבצי טקסט, תמונות, סרטונים
        </span>
      </BaseButton>
      <input
        ref="fileInput"
        class="sr-only hidden"
        type="file"
        @change="addFile"/>
    </template>
  </BaseWidget>
  <BaseToast
    v-if="isSuccess"
    type="success"
    class="fixed bottom-5 right-5 p-4 rounded-lg z-50 border-1 border-green-500">
    <span class="mr-4 font-simpler text-md text-green-500">
      קובץ הועלה בהצלחה
    </span>
  </BaseToast>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, resolveComponent } from 'vue';
import {
  BaseButton,
  BaseToast,
  BaseWidget,
  PlusIcon,
} from '@nwire/amit-design-system';
import fileText from '/@/assets/fileText.svg';
import UploadIcon from '/@/assets/upload.svg?component';
import { Trash2 } from 'lucide-vue-next';
import { deleteFile, listFiles, uploadFile } from '/@/app/services/courses';
import { useAccountStore } from '/@/app/store/account';
import swal from 'sweetalert';
import { showAlert } from '/@/app/utils';

const LoadingOverlay = resolveComponent('LoadingOverlay');

const props = defineProps({
  courseId: {
    type: String,
    required: true,
  },
});

const file = ref(null);
const fileInput = ref(null);
const fileItems = ref([] as any);
const accountStore = useAccountStore();
const isUploading = ref(false);
const isSuccess = ref(false);

const userId = accountStore.user.id;
const MAX_FILE_SIZE_MB = 2;

onMounted(async () => {
  const { onSuccess } = listFiles(props.courseId, userId);
  onSuccess(({ data }: { data: any }) => {
    fileItems.value = data;
  });
});

const fileName = computed(() => (file as any).value?.name);

const triggerFileInput = () => {
  (fileInput as any).value.click();
};

/**
 * @param event
 */
const addFile = event => {
  file.value = event.target.files[0];
  if (file.value && !validateFile(file)) {
    return;
  }

  isUploading.value = true;
  const formData = new FormData();
  formData.append('files', file.value as any);
  formData.append('fileName', fileName.value);

  const { onSuccess, onError } = uploadFile(
    formData,
    props.courseId,
    accountStore.user.id,
  );
  onSuccess(({ data }) => {
    fileItems.value.push(data);
    isUploading.value = false;
    isSuccess.value = true;
    setTimeout(() => {
      isSuccess.value = false;
    }, 5000);
  });
  onError(error => {
    showAlert(
      `אירעה שגיאה ${error?.status}`,
      error.message || 'אנא נסה שוב מאוחר יותר',
      'error',
      {
        confirm: {
          text: 'ביטול',
        },
      },
    );

    isUploading.value = false;
  });
};

const validateFile = file => {
  if (!file.value) {
    return false;
  }
  const fileType = (file.value as any).type;

  // Define the allowed file types
  const allowedFileTypes = [
    'application/pdf',
    'application/msword', // Microsoft Word - .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (OpenXML) - .docx
    'text/plain', // .txt
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/x-msvideo', // .avi
    'video/quicktime', // .mov
    'video/x-ms-wmv', // .wmv
    'video/x-flv', // .flv
    'video/mpeg', // .mpg
    'video/webm', // .webm
    'video/mp4',
  ];

  // Check if the file type is allowed
  if (!allowedFileTypes.includes(fileType)) {
    showAlert(
      'סוג הקובץ לא מותר',
      'ניתן להעלות רק קבצים מסוג PDF, טקסט, תמונות או סרטונים',
      'error',
      {
        confirm: {
          text: 'הבנתי',
        },
      },
    );
    return false;
  }
  if ((file.value as any).size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    showAlert('הקובץ גדול מידי', 'הגודל המרבי המותר הוא 2MB', 'error', {
      confirm: {
        text: 'הבנתי',
      },
    });
    return false;
  }
  return true;
};

const removeFile = async fileId => {
  showAlert('האם אתה בטוח?', 'הקובץ יימחק לצמיתות', 'warning', [
    'ביטול',
    'מחק',
  ]).then(willDelete => {
    if (willDelete) {
      const { onSuccess, onError } = deleteFile(fileId);
      onSuccess(() => {
        fileItems.value = fileItems.value.filter(item => item._id !== fileId);
        swal('הקובץ נמחק בהצלחה', {
          icon: 'success',
        });
      });
      onError(error => {
        console.log(error);
      });
    }
  });
};
</script>
