<template>
  <BaseModal v-show="isModalOpened" @close="closeModal">
    <template #body>
      <div dir="rtl" class="flex flex-col gap-4 text-center">
        <UnlockLessonIcon class="mx-auto" />
        <span class="text-xl font-anomaliaBold">
          {{ $t('survey_page.timing_unlock_survey') }}
        </span>
        <span class="text-base w-75 text-center mx-auto">
          {{ $t('survey_page.timing_unlock_survey_msg') }}
          {{ selectedQuestionnaire?.questionnaire?.title }} ?
        </span>
        <div class="w-50 mx-auto">
          <DatePicker
            v-model="selectedDate"
            :min-date="new Date()"
            :max-date="add(new Date(), { months: 6 })"
            :clearable="false"
            :prevent-min-max-navigation="true"
            @select-dates="updateDate"/>
        </div>
        <div class="flex justify-end items-center">
          <PVButton
            severity="neutral"
            text
            label="ביטול"
            @click="isModalOpened = false"/>
          <PVButton
            label="אישור תזמון"
            :disabled="!selectedDate"
            :loading="loading"
            @click="unlockQuest"/>
        </div>
      </div>
    </template>
  </BaseModal>
  <BaseTable
    v-if="mappedStudents.length && enrollmentsSummary.length"
    :dence="true"
    :headers="tableHeaders"
    :items="mappedStudents"
    include-search
    label="תלמידים"
    selection-mode="single"
    @row-click="onRowSelect">
    <template #columnheader="{ header }">
      <div
        v-if="header.summary"
        :class="'font-semibold text-' + header.align"
        class="flex gap-2 w-full h-full items-center text-sm py-2 px-3">
        <button
          class="p-3 rounded-full mb-1"
          :class="{
            'cursor-default bg-primary-200': !header.summary?.locked,
            'bg-secondary-300':
              hasFutureDate(header.summary.unlock_at) || header.summary?.locked,
          }"
          :disabled="!header.summary?.locked"
          @click="
            () => {
              openModal(header.summary);
            }
          ">
          <Unlock
            v-if="!header.summary?.locked"
            class="h-3 w-3 fill-primary-200 stroke-primary"/>
          <Lock
            v-else-if="
              header.summary?.locked && !hasFutureDate(header.summary.unlock_at)
            "
            class="h-3 w-3 fill-secondary-300 stroke-[#B0BAC8]"/>
          <Clock10
            v-else-if="hasFutureDate(header.summary.unlock_at)"
            class="h-3 w-3 fill-red-300 stroke-[#a64c4c]"/>
        </button>
        <div class="flex flex-col items-start">
          <span class="whitespace-nowrap">{{ header.title }}</span>
        </div>
      </div>
      <div
        v-else
        class="p-2 whitespace-nowrap"
        :class="'font-semibold text-' + header.align">
        {{ header.title }}
      </div>
    </template>

    <template #columnbody="{ header, item }">
      <div class="cursor-pointer flex flex-1 items-center h-full">
        <div v-if="header.key === 'studentName'" class="flex flex-col p-2">
          <span class="truncate font-simplerBold text-secondary-900">
            {{ item.studentName }}
          </span>
        </div>
        <div
          v-else-if="header.key === 'averageScore'"
          class="flex justify-start p-4 text-secondary-900 font-simplerBold border-r">
          {{ item.averageScore }}
        </div>
        <div v-else class="flex flex-1 justify-center items-center">
          <span
            class="rounded-full p-1 h-6 w-6 flex justify-center items-center"
            :class="{
              'bg-secondary-250 text-secondary-900':
                !isFinished(item.id, header.questionnaireId) ||
                header.summary?.locked,
              'bg-green-spring-100 text-green-spring-700': isFinished(
                item.id,
                header.questionnaireId,
              ),
            }">
            <Check
              v-if="isFinished(item.id, header.questionnaireId)"
              :size="15"
              class="text-green-spring-700"/>
            <Lock
              v-else-if="header.summary?.locked"
              :size="12"
              class="text-secondary-800"/>
            {{
              !isFinished(item.id, header.questionnaireId) &&
              !header.summary?.locked
                ? '-'
                : ''
            }}
          </span>
        </div>
      </div>
    </template>
  </BaseTable>
  <BaseToast />
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { find, get, keyBy } from 'lodash';
import { BaseModal, DatePicker } from '@nwire/amit-design-system';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import BaseToast from '/@/views/playground/components/BaseToast.vue';
import type { IStudent } from '/@/app/models/interfaces';
import { RouteParams, useRoute, useRouter } from 'vue-router';
import { useStudentsStore } from '/@/app/store/students';
import { unlockQuestionnaire } from '/@/app/services/surveys';
import { add, isToday, isDate, isFuture } from 'date-fns';
import UnlockLessonIcon from '/@/assets/unlock_lesson_modal.svg?component';
import { sortStudents } from '/@/utils';
import { Check, Lock, Unlock, Clock10 } from 'lucide-vue-next';
import PVButton from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { onUnmounted } from 'vue';

const toast = useToast();

const props = defineProps({
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
});

const studentsStore = useStudentsStore();
const enrollmentsSummary = computed(() =>
  get(studentsStore.surveyEnrollments[0], 'summary', []),
);
const selectedQuestionnaire = ref();
const isModalOpened = ref(false);
const selectedDate: any = ref(new Date());
const loading = ref(false);
const route = useRoute();
const router = useRouter();
const { group, course: survey }: RouteParams | { group: any; course: any } =
  route.params;

const sortedStudents = computed(() => sortStudents(props.students));

const enrollmentSummaryPerStudent = computed(() =>
  keyBy(
    studentsStore.surveyEnrollments?.map(({ user: { id }, summary }) => ({
      id,
      summary: keyBy(
        summary.map(({ questionnaire, ...other }) => ({
          ...other,
          questionnaire,
          id: questionnaire.id,
        })),
        'id',
      ),
    })),
    'id',
  ),
);

const getStudentQuestionareStatus = (studentId, questionnaireId) =>
  enrollmentSummaryPerStudent.value[studentId].summary[questionnaireId].status;
const isFinished = (studentId, questionnaireId) =>
  getStudentQuestionareStatus(studentId, questionnaireId) === 'finished';

const tableHeaders = computed(() => [
  {
    title: 'תלמידים',
    align: 'start',
    key: 'studentName',
    sortable: true,
    frozen: true,
    style: 'width: 6rem',
  },
  ...enrollmentsSummary.value.map((summary, index) => ({
    title: `${summary.questionnaire.title}`,
    align: 'start',
    key: `questionnaire-${index}`,
    style: 'width: 10rem',
    questionnaireId: summary.questionnaire.id,
    summary,
  })),
  {
    title: 'שאלונים שבוצעו',
    align: 'right',
    key: 'averageScore',
    frozen: true,
    class: '!left-0',
  },
]);

onUnmounted(() => studentsStore.clearSurveyEnrollments());

const mappedStudents = computed(() => {
  return sortedStudents.value
    .filter(student => enrollmentSummaryPerStudent.value[student.id])
    .map(student => ({
      id: student.id,
      studentName: student.fullName,
      averageScore: averageLessons(student.id),
      ...enrollmentsSummary.value.reduce((acc, summary, index) => {
        acc[`questionnaire-${index}`] = summary.questionnaire.id; // Add dynamic lesson keys
        return acc;
      }, {}),
    }));
});

const unlockQuest = async () => {
  loading.value = true;
  const { onSuccess, onError } = unlockQuestionnaire({
    survey,
    group,
    questionnaire: selectedQuestionnaire.value.questionnaire.id,
    unlock_at: selectedDate.value,
  });
  onSuccess(() => {
    loading.value = false;
    studentsStore.fetchSurveyEnrollments({ group, survey });
    toast.add({
      severity: 'success',
      summary: 'השאלון נפתח בהצלחה!',
      life: 4000,
    });
    isModalOpened.value = false;
  });
  onError(() => {
    loading.value = false;
    toast.add({
      severity: 'error',
      summary: 'פתיחת השאלון נכשלה',
      life: 7000,
    });
  });
};

const updateDate = e => (selectedDate.value = e.dates);

const averageLessons = studentId => {
  const enrollment = find(
    studentsStore.surveyEnrollments,
    (enrollment: any) => enrollment.user.id === studentId,
  );
  return enrollment
    ? `${
        enrollment?.summary?.filter(
          (summary: any) => summary.status === 'finished',
        ).length
      }/${enrollment?.summary?.length}`
    : 'אין מידע';
};

const closeModal = () => {
  selectedDate.value = new Date();
  selectedQuestionnaire.value = null;
  isModalOpened.value = false;
};

const openModal = data => {
  if (data.selectedDate) {
    selectedDate.value = data.selectedDate;
  }
  selectedQuestionnaire.value = data;
  isModalOpened.value = true;
};

const onRowSelect = event => {
  router.push({
    name: 'survey-progress-visual',
    params: {
      group,
      survey,
    },
    query: {
      student: event.data.id,
    },
  });
};

const hasFutureDate = date => {
  const dateObj = new Date(date);
  if (!isDate(dateObj) || isToday(dateObj)) return false;
  return isFuture(dateObj);
};
</script>
