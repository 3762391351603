<template>
  <div class="flex gap-5 w-full">
    <div class="flex-1 h-full shadow-sm rounded-xl">
      <BaseWidget class="h-full">
        <template #title>
          <div class="font-simplerBold">תלמידים בכיתה</div>
        </template>
        <template #default>
          <ScrollableContainer class="min-h-120">
            <VueDraggable
              v-model="studentsToAssign"
              class="flex flex-col h-full"
              :sort="false"
              group="students"
              :animation="200">
              <template #item="{ element }">
                <div
                  class="flex gap-2 p-2 rounded-lg cursor-pointer hover:bg-primary-200">
                  <img
                    :src="element.avatar || defaultAvatarImage"
                    :alt="element.fullName"
                    class="h-6 w-6 rounded-full"/>
                  <span>{{ element.fullName }}</span>
                </div>
              </template>
            </VueDraggable>
            <div
              v-if="!studentsToAssign.length"
              class="absolute inset-x-0 top-1/2 -translate-y-1/2 flex flex-col gap-2 items-center">
              <img :src="LampImage" class="w-20" alt="lamp_img" />
              <div class="font-simplerBold">
                מעולה! כל התלמידים חולקו לקבוצות!
              </div>
            </div>
          </ScrollableContainer>
        </template>
      </BaseWidget>
    </div>

    <div class="flex-1 h-full shadow-sm rounded-xl">
      <BaseWidget class="h-full">
        <template #title>
          <div class="font-simplerBold">קבוצות</div>
        </template>
        <template #default>
          <ScrollableContainer class="min-h-120">
            <div class="flex flex-col gap-4 p-2">
              <div v-if="formattedTeams.length" class="flex flex-col gap-4">
                <div v-for="(team, index) in formattedTeams" :key="team.id">
                  <div class="flex flex-col gap-2">
                    <div class="flex gap-2 items-center">
                      <div
                        contenteditable
                        class="flex-1 outline-primary p-1 rounded"
                        @blur="event => updateTeamName(event, index)">
                        {{ team.name }}
                      </div>
                      <div class="w-6">
                        <TrashIcon
                          class="w-4 h-4 cursor-pointer stroke-red-700"
                          @click="handleDeleteTeam(team)"/>
                      </div>
                    </div>
                    <VueDraggable
                      v-model="team.students"
                      class="p-2 flex flex-col min-h-10 rounded outline-dashed outline-2 outline-offset-1"
                      :class="[
                        team.students.length
                          ? 'outline-primary-400'
                          : 'outline-secondary-400',
                      ]"
                      :sort="false"
                      group="students"
                      :animation="200"
                      @change="setTeamsName">
                      <template #item="{ element }">
                        <div
                          class="flex gap-2 p-2 rounded-lg cursor-pointer hover:bg-primary-200">
                          <img
                            :src="element.avatar || defaultAvatarImage"
                            :alt="element.fullName"
                            class="h-6 w-6 rounded-full"/>
                          <span>{{ element.fullName }}</span>
                        </div>
                      </template>
                    </VueDraggable>
                  </div>
                </div>
                <div
                  v-if="hasEmptyFirstTeam"
                  class="flex flex-col items-center">
                  <img :src="ArrowRightIcon" class="w-15" alt="arrow" />
                  <div class="font-simplerBold text-center">
                    גררו מהצד השני את התלמידים לקבוצה החדשה שיצרתם
                  </div>
                </div>
              </div>
              <div v-else class="flex flex-col items-center">
                <img :src="HatWithBookImage" class="w-20" alt="hat_img" />
                <div class="font-simplerBold text-center">
                  אין פה עדיין קבוצות, הקימו את הקבוצה הראשונה!
                </div>
              </div>
              <BaseButton
                v-if="!hasEmptyFirstTeam"
                class="flex items-center gap-2 justify-center w-full h-8.5 border !border-primary bg-white focus:outline-none focus:ring-0"
                color="light"
                @click="addTeam">
                <PlusCircle class="stroke-primary" />
                <span class="font-simplerBold text-primary">קבוצה חדשה</span>
              </BaseButton>
            </div>
          </ScrollableContainer>
        </template>
      </BaseWidget>
    </div>
    <div
      ref="dragPreview"
      class="drag-preview rounded-4xl bg-primary-200"
      :style="{ top: `${dragPositionY}px`, left: `${dragPositionX}px` }"></div>
  </div>

  <BaseModal
    :show="showDeleteTeamModal"
    size="md"
    @close="closeDeleteTeamModal">
    <template #header>
      <div class="font-simplerBold">מחיקת הקבוצה {{ teamToDelete.name }}</div>
    </template>
    <template #body>
      <div>
        <div>האם למחוק את {{ teamToDelete.name }} ?</div>
      </div>
    </template>
    <template #footer>
      <div class="flex gap-2 justify-end">
        <BaseButton
          class="flex items-center justify-center w-18 h-8.5 rounded-xl border !border-red-800 bg-white text-2xl font-simplerBold focus:outline-none"
          color="light"
          @click="deleteTeam">
          <span class="text-red-800">מחיקה</span>
        </BaseButton>
        <BaseButton
          class="flex items-center justify-center w-18 h-8.5 rounded-xl bg-primary text-2xl font-simplerBold focus:outline-none"
          @click="closeDeleteTeamModal">
          <span class="text-white">חזרה</span>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import {
  BaseWidget,
  BaseButton,
  BaseModal,
  ScrollableContainer,
} from '@nwire/amit-design-system';
import { ref, computed, watch, PropType, nextTick } from 'vue';
import LampImage from '/@/assets/lamp.svg?url';
import ArrowRightIcon from '/@/assets/arrowRight.svg?url';
import HatWithBookImage from '/@/assets/hatWithBook.svg?url';
import { PlusCircle, Trash as TrashIcon } from 'lucide-vue-next';
import VueDraggable from 'vuedraggable';
import { generateObjectId } from '@/utils';
import { IStudent, ITeam } from '/@/app/types/interfaces';
import { useDrawer } from '@amit/layout';
import { defaultAvatarImage } from '@/utils/helpers';

const props = defineProps({
  students: {
    type: Array as PropType<IStudent[]>,
    required: true,
  },
  teams: {
    type: Array as PropType<ITeam[]>,
    required: true,
  },
});

const emit = defineEmits(['save', 'close']);

const { onDrawerAction, onDrawerClose } = useDrawer();

const defaultTeam = {
  name: '',
  students: [],
  type: 'default',
};

const studentsToAssign = ref<IStudent[]>([]);
const formattedTeams = ref<ITeam[]>([]);
const dragPreview = ref();
const dragPositionX = ref();
const dragPositionY = ref();
const showDeleteTeamModal = ref(false);
const teamToDelete = ref();

const hasEmptyFirstTeam = computed(() => {
  return (
    formattedTeams.value.length === 1 &&
    formattedTeams.value[0].students?.length === 0
  );
});

const setTeams = () => {
  formattedTeams.value = JSON.parse(JSON.stringify(props.teams ?? []));
};

const setStudents = () => {
  studentsToAssign.value = JSON.parse(JSON.stringify(props.students ?? []));
};

watch(
  () => props.teams,
  () => {
    setTeams();
  },
  { immediate: true },
);

watch(
  () => props.students,
  () => {
    setStudents();
  },
  { immediate: true },
);

const setTeamsName = () => {
  for (let index = 0; index < formattedTeams.value.length; index++) {
    if (formattedTeams.value[index].customName) {
      continue;
    }
    const students = formattedTeams.value[index].students
      .map(student => student.fullName)
      .join(', ');
    formattedTeams.value[index].name = `${index + 1}. ${students}`;
  }
};

const addTeam = () => {
  const newTeam = JSON.parse(JSON.stringify(defaultTeam));
  newTeam.id = generateObjectId();
  newTeam.name = `${formattedTeams.value.length + 1}. קבוצה חדשה`;
  formattedTeams.value.push(newTeam);
};

const closeDeleteTeamModal = () => {
  showDeleteTeamModal.value = false;
};

const handleDeleteTeam = team => {
  teamToDelete.value = team;
  showDeleteTeamModal.value = true;
};

const deleteTeam = () => {
  const index = formattedTeams.value.findIndex(
    team => team.id === teamToDelete.value.id,
  );
  const deletedTeam = formattedTeams.value.splice(index, 1);
  studentsToAssign.value.push(...deletedTeam[0].students);
  setTeamsName();
  closeDeleteTeamModal();
};

const updateTeamName = (event, index) => {
  const newName = event.target.innerText;
  const oldName = formattedTeams.value[index].name;
  if (newName === oldName) {
    return;
  }

  if (newName === '') {
    formattedTeams.value[index].customName = false;
    formattedTeams.value[index].name = '';
    nextTick(() => {
      setTeamsName();
    });
    return;
  }

  formattedTeams.value[index].name = newName;
  formattedTeams.value[index].customName = true;
};

onDrawerClose(() => {
  setStudents();
  setTeams();
});

onDrawerAction.on(() => {
  emit('save', formattedTeams.value);
});
</script>
