<template>
  <BaseTable
    v-if="mappedStudents.length"
    class="!h-[650px]"
    :headers="tableHeaders"
    :items="mappedStudents"
    :allow-download="true"
    include-search
    label="תלמידים">
    <template #columnheader="{ header }">
      <div
        class="flex flex-col gap-0.5 px-3 w-full cursor-pointer"
        @click="
          () =>
            header.lesson?.id &&
            $router.push({
              name: 'review-submissions',
              params: { group, course, lesson: header.lesson.id },
              query: { review_per: 'exercise' },
            })
        ">
        <div
          v-tooltip.top="header.title"
          class="flex gap-1"
          :class="[header.locked ? 'text-secondary-450' : 'text-secondary-900']">
          <span v-if="header.position" class="font-simplerLight text-sm">
            {{ header.position }}
          </span>
          <span
            class="font-simplerLight text-sm truncate"
            :dir="header.rtl ? 'rtl' : 'ltr'">
            {{ header.title }}
          </span>
        </div>
        <div v-if="header.weight">
          <div class="font-simplerLight text-secondary-900 text-xs">
            {{ header.weight }}%
          </div>
        </div>
      </div>
    </template>

    <template #columnbody="{ header, item }">
      <div
        v-if="header.key === 'student'"
        class="flex items-center gap-1 px-2"
        :class="{ 'opacity-50': !item.enrollment }">
        <img
          class="rounded-full w-4 h-4"
          :src="item.avatar || defaultAvatarImage"
          :alt="item.fullName"/>
        <span v-tooltip.top="item.student" class="truncate">
          {{ item.student }}
        </span>
      </div>
      <div v-else-if="!item.enrollment" class="w-full h-full bg-white"></div>
      <div v-else-if="header.key === 'progress'">
        <ProgressInfo
          :progress="Math.round(item.totalProgress)"
          :items-progress="getLessonsProgress(item.enrollment)"
          :border="false"/>
      </div>
      <div v-else-if="header.key === 'score'" class="text-secondary-900">
        {{ item.totalScore }}
      </div>
      <div
        v-else-if="header.locked"
        class="w-full h-full flex items-center justify-center">
        <span class="text-secondary-450">-</span>
      </div>
      <ScoreInfo
        v-else
        :class="{ 'cursor-pointer': header.lesson?.id }"
        :lesson-summary="getLessonSummary(item.enrollment, header.lesson?.id)"
        @click="
          () =>
            header.lesson?.id &&
            $router.push({
              name: 'review-submissions',
              params: { group, course, lesson: header.lesson.id },
              query: { review_per: 'student', student: item.id },
            })
        "/>
    </template>
  </BaseTable>
  <div
    v-else
    class="flex items-center justify-center font-anomaliaLight text-lg pt-5">
    {{ $t('course_page.no_mentorship_teachers') }}
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import type { IStudent } from '/@/app/models/interfaces';
import ScoreInfo from '/@/views/playground/partials/ScoreInfo.vue';
import { useStudentsStore } from '/@/app/store/students';
import { defaultAvatarImage } from '@/utils/helpers';
import { ProgressInfo } from '@amit/components';

const props = defineProps({
  courseModule: {
    type: Object,
    required: true,
  },
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
});

const studentsStore = useStudentsStore();
const group = computed(() => props.courseModule.group);
const course = computed(() => props.courseModule.course.id);
const summary = computed(() => props.courseModule.summary ?? []);
const criteria = computed(() => props.courseModule.criteria ?? []);

const tableHeaders = computed(() => [
  {
    title: 'תלמידים',
    key: 'student',
    sortable: true,
    frozen: true,
    style: 'min-width: 120px; max-width: 120px',
  },
  {
    title: 'התקדמות בקורס',
    key: 'progress',
    style: 'min-width: 120px; max-width: 120px',
  },
  ...summary.value.map((item, index) => ({
    title: item.lesson.title,
    position: `${item.lesson.lesson}.`,
    rtl: item.lesson.display.rtl ?? true,
    key: `lesson-${index}`,
    lesson: item.lesson,
    locked: item.locked,
    weight: item.weight,
    style: 'min-width: 120px; max-width: 120px',
  })),
  ...criteria.value.map((item, index) => ({
    title: item.name,
    key: `criterion-${index}`,
    weight: item.weight,
    style: 'min-width: 120px; max-width: 120px',
  })),
  // {
  //   title: 'ציון הקורס',
  //   key: 'score',
  //   style: 'width: 120px',
  // },
]);

const mappedStudents = computed(() => {
  return props.students.map(student => {
    const enrollment = studentsStore.enrollments.find(
      (enrollment: any) => enrollment.student.id === student.id,
    );
    return {
      id: student.id,
      student: student.fullName,
      avatar: student.avatar,
      totalProgress: enrollment?.total_progress,
      totalScore: enrollment?.total_score,
      enrollment,
    };
  });
});

const getLessonSummary = (enrollment, lesson) => {
  return enrollment.summary.find(item => item.lesson.id === lesson) ?? {};
};

const getLessonsProgress = enrollment => {
  return enrollment.summary.map(item => item.progress);
};
</script>
