<script lang="ts" setup>
import { ref, toRefs, watchEffect } from 'vue';
import type { PropType } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useInputClasses } from '@/components/Input/composables/useInputClasses';
import type { InputSize, InputVariant } from '@/components/Input/types';
import { format as dateFnsFormat } from 'date-fns';

const props = defineProps({
  modelValue: {
    type: [Date, Array, String] as PropType<Date | string | Date[] | string[]>,
    default: null,
  },
  modelName: { type: String, default: null },
  clearable: { type: Boolean, default: true },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<
      | 'button'
      | 'checkbox'
      | 'color'
      | 'date'
      | 'datetime-local'
      | 'email'
      | 'file'
      | 'hidden'
      | 'image'
      | 'month'
      | 'number'
      | 'password'
      | 'radio'
      | 'range'
      | 'reset'
      | 'search'
      | 'submit'
      | 'tel'
      | 'text'
      | 'time'
      | 'url'
      | 'week'
    >,
    default: 'text',
  },
  size: {
    type: String as PropType<InputSize>,
    default: 'md',
  },
  variant: {
    type: String as PropType<InputVariant>,
    default: 'default',
  },
  label: { type: String, default: null },
  useRange: { type: Boolean, default: false },
  minRange: { type: Number, default: null },
  maxRange: { type: Number, default: null },
  flow: {
    type: Array as PropType<
      (
        | 'time'
        | 'month'
        | 'year'
        | 'calendar'
        | 'minutes'
        | 'hours'
        | 'seconds'
      )[]
    >,
    default: null,
  },
  textInput: { type: Boolean, default: false },
  inline: { type: Boolean, default: false },
  multiDates: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  success: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  minDate: { type: String, default: null },
  maxDate: { type: String, default: null },
  preventMinMaxNavigation: { type: Boolean, default: false },
  allowedDates: {
    type: Array as PropType<
      (
        | 'month'
        | 'year'
        | 'calendar'
        | 'time'
        | 'minutes'
        | 'hours'
        | 'seconds'
      )[]
    >,
    default: null,
  },
});

const emit = defineEmits(['select-dates']);

const dateValue: any = ref(props.modelValue);

const handleDate = modelData => {
  dateValue.value = modelData;
  emit('select-dates', { dates: dateValue.value });
};

const format = date => dateFnsFormat(date, 'dd/MM/yyyy');

const { inputClasses } = useInputClasses(toRefs(props));

watchEffect(() => {
  dateValue.value = props.modelValue;
});
</script>
<template>
  <FormControl
    :title="title"
    :sub-title="subTitle"
    :label="label"
    :disabled="disabled">
    <VueDatePicker
      dir="ltr"
      class="transition transform ease-in-out delay-150 w-full focus:ring-0 focus:outline-none"
      :class="inputClasses"
      locale="he-IL"
      :day-names="['ב', 'ג', 'ד', 'ה', 'ו', 'ש', 'א']"
      :range="useRange"
      :partial-range="useRange"
      :min-range="minRange"
      :max-range="maxRange"
      :flow="flow"
      :name="modelName"
      :text-input="textInput"
      :inline="inline"
      :multi-dates="multiDates"
      :disabled="disabled"
      :enable-time-picker="false"
      :auto-apply="true"
      :allowed-dates="allowedDates"
      :max-date="maxDate"
      :min-date="minDate"
      :model-value="dateValue"
      :clearable="clearable"
      :format="format"
      :prevent-min-max-navigation="preventMinMaxNavigation"
      @update:model-value="handleDate">
      <template #input-icon>
        <!--        <img class="input-slot-image" src="/logo.png" @click="clear" />-->
      </template>
    </VueDatePicker>
    <p
      v-if="$slots.tip"
      class="mt-2 text-sm text-secondary-500 dark:text-secondary-400">
      <slot name="tip" />
    </p>
  </FormControl>
</template>

<style>
@import '@vuepic/vue-datepicker/dist/main.css';
.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
  @apply bg-transparent text-center py-0 ring-0 border-0 outline-none focus:ring-0 focus:outline-none;
}
.dp__disabled {
  @apply bg-transparent text-center border-0 py-0;
}
.dp__input_wrap {
  @apply ring-0 outline-none focus:ring-0 focus:outline-none;
}
.dp__input_icon {
  right: 0;
  left: auto;
}
.dp__clear_icon {
  right: auto;
  left: 0;
}
.dp__btn,
.dp__calendar_header_item,
.dp__flex_row,
.dp__cell_inner {
  @apply font-simplerLight;
}

.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
  @apply font-simplerLight text-sm;
}
</style>
