<template>
  <BaseWidget>
    <template #title>
      <div>קבוצות לימוד בקורס</div>
    </template>
    <template #default>
      <ScrollableContainer class="min-h-40">
        <div v-if="teams.length" class="w-full flex flex-col gap-4">
          <div
            v-for="team in teams"
            :key="team.id"
            class="flex items-center gap-5">
            <div class="flex items-center gap-2">
              <div
                class="h-6 w-6 min-w-6 flex items-center justify-center bg-primary rounded-full">
                <img :src="AmitLogo" alt="amit_logo" class="h-4 w-4" />
              </div>
              <span>{{ team.name }}</span>
            </div>
          </div>
          <div v-if="students.length" class="flex flex-col gap-2">
            <div class="font-simplerBold">לא משוייכים</div>
            <div
              v-for="student in students"
              :key="student.id"
              class="flex gap-2">
              <img
                :src="student.avatar || defaultImage"
                :alt="student.fullName"
                class="h-6 w-6 rounded-full"/>
              <span>{{ student.fullName }}</span>
            </div>
          </div>
        </div>
        <div v-else class="h-full flex flex-col items-center justify-center">
          <img :src="HatWithBookImage" class="w-20" alt="hat_img" />
          <div class="font-simplerBold">אין פה עדיין קבוצות</div>
        </div>
      </ScrollableContainer>
    </template>
    <template #footer>
      <div
        class="flex justify-center items-center gap-1 font-simplerBold text-primary-700 cursor-pointer"
        @click="openDrawer()">
        <template v-if="teams.length">
          <UsersIcon class="stroke-primary" />
          <span>עריכת קבוצות</span>
        </template>
        <template v-else>
          <SparklesIcon class="stroke-primary" />
          <span>חלוקה לקבוצות</span>
        </template>
      </div>
    </template>
  </BaseWidget>

  <SideDrawer :title="teamsBuilderTitle" @action="saveTeams">
    <TeamsBuilder :students="students" :teams="teams" @save="saveTeams" />
    <template #footer></template>
  </SideDrawer>
</template>

<script setup lang="ts">
import { BaseWidget, ScrollableContainer } from '@nwire/amit-design-system';
import { computed } from 'vue';
import { setTeams } from '/@/app/services/groups';
import HatWithBookImage from '/@/assets/hatWithBook.svg?url';
import AmitLogo from '/@/assets/amitWhiteLogo.svg?url';
import { SideDrawer, useDrawer } from '@amit/layout';
import TeamsBuilder from '/@/views/partials/TeamsBuilder.vue';
import { useAccountStore } from '/@/app/store/account';
import { useStudentsStore } from '/@/app/store/students';
import { IStudent, ITeam } from '/@/app/types/interfaces';
import { Sparkles as SparklesIcon, Users2 as UsersIcon } from 'lucide-vue-next';

const props = defineProps({
  courseModule: {
    type: Object,
    required: true,
  },
});

const accountStore = useAccountStore();
const studentsStore = useStudentsStore();

const defaultImage =
  'https://res.cloudinary.com/dcodkxpej/image/upload/v1725192676/default-avatar-image_adpnhe.png';

const { openDrawer, closeDrawer } = useDrawer();

const groupId = computed(() => props.courseModule.group);
const courseId = computed(() => props.courseModule.course.id);
const groupStudents = computed<IStudent[]>(() => {
  const group = accountStore.groups.find(({ id }) => id === groupId.value);
  return group?.students ?? [];
});
const students = computed<IStudent[]>(() => {
  const teamsStudents = teams.value.flatMap(team =>
    team.students.map(student => student.id),
  );

  return groupStudents.value.filter(
    student => !teamsStudents.includes(student.id),
  );
});

const isCustomName = (team: ITeam, index) => {
  const studentsName = team.students
    .map(student => student.fullName)
    .join(', ');
  const name = `${index + 1}. ${studentsName}`;
  return name !== team.name;
};

const teams = computed<ITeam[]>(() => {
  const teams = props.courseModule?.teams ?? [];
  return teams.map((team, index) => {
    return {
      ...team,
      customName: isCustomName(team, index),
    };
  });
});

const teamsBuilderTitle = computed(() => {
  return `קבוצות לימוד ל${props.courseModule?.course.caption ?? ''}`;
});

const saveTeams = (formattedTeams: ITeam[]) => {
  const teams = formattedTeams.flatMap(({ id, name, students, type }) => {
    if (!students.length) {
      return [];
    }

    return {
      id,
      name,
      type,
      students: students.map(student => student.id),
    };
  });

  const { onSuccess, onError } = setTeams({
    group: groupId.value,
    module: props.courseModule.id,
    teams,
  });

  onSuccess(() => {
    studentsStore.setCourseModule(groupId.value, courseId.value, true);
    closeDrawer();
  });
  onError(() => {
    console.log('error');
    closeDrawer();
  });
};
</script>
