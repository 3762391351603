<template>
  <div class="flex flex-1 relative">
    <div class="main-scrollable-container">
      <div class="content-scrollable-wrapper w-full">
        <div
          class="overflow-container"
          :class="{
            'hide-scrollbar': props.hidescrollbar,
          }">
          <div class="h-full">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  hidescrollbar: Boolean,
});
</script>

<style scoped>
/* The main container */
.main-scrollable-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide for Chrome */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* this is the flex container that will take the rest of the height */
.content-scrollable-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px;
}

/* the container for our overflowed content */
.overflow-container {
  flex: 1;
  overflow: auto;
  direction: ltr;
  /*  to change the position of the scrollbar (move it to the right side) */

  > div {
    direction: rtl;
  }
}

::-webkit-scrollbar {
  width: 10px !important;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-left: 4px solid transparent;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
</style>
