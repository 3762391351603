<template>
  <BaseWidget class="border-r-0">
    <template #default>
      <BarChart
        :key="dataset.length"
        :data="distribution"
        :height="200"
        title="התפלגות תלמידים בקורס"/>
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { BaseWidget, BarChart } from '@nwire/amit-design-system';
import { computed, onMounted, ref } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import { collect } from 'collect.js';
import { Collection } from 'collect.js';
import { fetchEnrollments } from '/@/app/services/students';
import type { EnrollmentData, IEnrollment } from '/@/app/models/interfaces';

const props = defineProps({
  group: {
    type: Object,
    default: () => ({}),
  },
  course: {
    type: Object,
    default: () => ({}),
  },
});

const labels: Ref<string[]> = ref([]);
const dataset: Ref<number[]> = ref([]);
const distribution: ComputedRef<EnrollmentData> = computed(() => ({
  labels: labels.value,
  datasets: [
    {
      label: 'מספר תלמידים',
      data: dataset.value,
      backgroundColor: '#0FA9FF',
      hoverBackgroundColor: '#0093E5',
      barPercentage: 0.76,
      categoryPercentage: 0.86,
    },
  ],
}));

const countStudents = enrollments => {
  return collect(enrollments)
    .reject((enrollment: any) => enrollment.summary.length <= 0)
    .map((enrollment: any) => {
      const lesson = enrollment.summary.find(
        l => l.lesson.id === enrollment.current_lesson,
      );

      return {
        currentLesson: lesson?.lesson?.lesson || -1,
      };
    })
    .groupBy('currentLesson')
    .map(group => (group as Collection<number>).count())
    .filter((item: any) => item)
    .all();
};

onMounted(() => {
  let { onSuccess } = fetchEnrollments(props.group?.id, props.course?.id);

  onSuccess(({ data }: { data: IEnrollment[] }) => {
    let progress = countStudents(data);
    let outline = collect(data).take(1).first()?.summary || [];
    labels.value = outline.map((_, i) => (i + 1).toString());
    dataset.value = labels.value.map(i => progress[i] || 0);
  });
});
</script>
